'use client';

import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import { useEffect, useMemo } from 'react';
// @mui
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
// components
import { useSettingsContext } from 'src/components/settings';
// system
import { palette } from './palette';
import { shadows } from './shadows';
import { typography } from './typography';
import { customShadows } from './custom-shadows';
import { componentsOverrides } from './overrides';
// options
import { presets } from './options/presets';
import { darkMode } from './options/dark-mode';
import { contrast } from './options/contrast';
import RTL, { direction } from './options/right-to-left';
//
import NextAppDirEmotionCacheProvider from './next-emotion-cache';
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum';


// ----------------------------------------------------------------------

export default function ThemeProvider({ children }) {
  const settings = useSettingsContext();

  const darkModeOption = darkMode(settings.themeMode);

  const presetsOption = presets(settings.themeColorPresets);

  const contrastOption = contrast(settings.themeContrast === 'bold', settings.themeMode);

  const directionOption = direction(settings.themeDirection);

  const baseOption = useMemo(
    () => ({
      palette: palette('light'),
      shadows: shadows('light'),
      customShadows: customShadows('light'),
      typography,
      shape: { borderRadius: 8 },
    }),
    []
  );

  const memoizedValue = useMemo(
    () =>
      merge(
        // Base
        baseOption,
        // Direction: remove if not in use
        directionOption,
        // Dark mode: remove if not in use
        darkModeOption,
        // Presets: remove if not in use
        presetsOption,
        // Contrast: remove if not in use
        contrastOption.theme
      ),
    [baseOption, directionOption, darkModeOption, presetsOption, contrastOption.theme]
  );

  const theme = createTheme(memoizedValue);

  theme.components = merge(componentsOverrides(theme), contrastOption.components);

  useEffect(() => {
    if (["prod","uat", "dev"].includes(process.env.NEXT_PUBLIC_ENV)){
      datadogLogs.init({
        service: `flexlis-reactjs-v2-${process.env.NEXT_PUBLIC_ENV}`,
        env: process.env.NEXT_PUBLIC_ENV,
        forwardConsoleLogs: ["error"],
        clientToken: 'pubefe65e93dee75541ff248f7cfc96a322',
        site: 'us5.datadoghq.com',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
      })
      // datadogLogs.logger.info('Button clicked', { name: 'buttonName', id: 123 })
      datadogRum.init({
        applicationId: 'd805d788-0410-479e-8b68-305eff55c597',
        clientToken: 'pub1393d9c4cac37a1dd9c8ed76737dadad',
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'us5.datadoghq.com',
        service: `flexlis-reactjs-v2-${process.env.NEXT_PUBLIC_ENV}`,
        env: process.env.NEXT_PUBLIC_ENV,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: 'v2',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
      let selectedUser = localStorage.getItem("selectedUser") ? JSON.parse(localStorage.getItem("selectedUser")) : {}
      datadogRum.setUser({
        name: selectedUser?.name,
        email: selectedUser?.email,
        role: selectedUser?.role,
        user_cid: selectedUser?.user_cid,
        lab_cid: selectedUser?.lab_cid,
      })
    }
  }, []);

  return (
    <NextAppDirEmotionCacheProvider options={{ key: 'css' }}>
      <MuiThemeProvider theme={theme}>
        <RTL themeDirection={settings.themeDirection}>
          <CssBaseline />
          {children}
        </RTL>
      </MuiThemeProvider>
    </NextAppDirEmotionCacheProvider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
};
