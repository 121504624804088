import(/* webpackMode: "eager" */ "/codebuild/output/src2290131747/src/flexlis-reactjs-v2/node_modules/@mui/material/node/Alert/index.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2290131747/src/flexlis-reactjs-v2/node_modules/react-toastify/dist/react-toastify.esm.mjs");
import(/* webpackMode: "eager" */ "/codebuild/output/src2290131747/src/flexlis-reactjs-v2/node_modules/simplebar-react/dist/simplebar.min.css");
import(/* webpackMode: "eager" */ "/codebuild/output/src2290131747/src/flexlis-reactjs-v2/node_modules/react-toastify/dist/ReactToastify.css");
import(/* webpackMode: "eager" */ "/codebuild/output/src2290131747/src/flexlis-reactjs-v2/node_modules/react-lazy-load-image-component/src/effects/blur.css");
import(/* webpackMode: "eager" */ "/codebuild/output/src2290131747/src/flexlis-reactjs-v2/node_modules/next/font/google/target.css?{\"path\":\"src/theme/typography.js\",\"import\":\"Inter\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"fallback\":[\"Helvetica\",\"Arial\",\"sans-serif\"]}],\"variableName\":\"primaryFont\"}");
import(/* webpackMode: "eager" */ "/codebuild/output/src2290131747/src/flexlis-reactjs-v2/node_modules/next/font/google/target.css?{\"path\":\"src/theme/typography.js\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"400\",\"500\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"fallback\":[\"Helvetica\",\"Arial\",\"sans-serif\"]}],\"variableName\":\"secondaryFont\"}");
import(/* webpackMode: "eager" */ "/codebuild/output/src2290131747/src/flexlis-reactjs-v2/src/auth/context/jwt/auth-consumer.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2290131747/src/flexlis-reactjs-v2/src/auth/context/jwt/auth-context.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2290131747/src/flexlis-reactjs-v2/src/auth/context/jwt/auth-provider.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2290131747/src/flexlis-reactjs-v2/src/auth/context/jwt/passwordless-provider.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2290131747/src/flexlis-reactjs-v2/src/components/animate/motion-lazy.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2290131747/src/flexlis-reactjs-v2/src/components/loader-wheel/index.jsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2290131747/src/flexlis-reactjs-v2/src/components/settings/context/settings-context.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2290131747/src/flexlis-reactjs-v2/src/components/settings/context/settings-provider.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2290131747/src/flexlis-reactjs-v2/src/components/settings/drawer/settings-drawer.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2290131747/src/flexlis-reactjs-v2/src/theme/index.js")