'use client';

import PropTypes from 'prop-types';

import {
  PasswordlessContextProvider,
} from "amazon-cognito-passwordless-auth/react";
import { Passwordless } from "amazon-cognito-passwordless-auth";

Passwordless.configure({
  cognitoIdpEndpoint: process.env.NEXT_PUBLIC_VITE_COGNITO_IDP_ENDPOINT,
  clientId: process.env.NEXT_PUBLIC_VITE_CLIENT_ID,
  fido2: {
    baseUrl: process.env.NEXT_PUBLIC_VITE_FIDO2_BASE_URL,
    authenticatorSelection: {
      userVerification: "required",
    },
  },  
  debug: console.debug,
});

export function PasswordlessProvider({ children }) {
  return (
    <PasswordlessContextProvider enableLocalUserCache={true}>
      {children}
    </PasswordlessContextProvider>
  );
}

PasswordlessProvider.propTypes = {
  children: PropTypes.node,
};

