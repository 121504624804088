"use client";

import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getStorage } from "src/hooks/use-local-storage";

const Loader = () => {
  const [isLoading, setIsLoading] = useState(() => {
    return getStorage("loader") || false;
  });

  useEffect(() => {
    const handleStorageChange = () => {
      const loaderVal = getStorage("loader");
      setIsLoading(loaderVal);
    };

    window.addEventListener("storageChange", handleStorageChange);

    return () => {
      window.removeEventListener("storageChange", handleStorageChange);
    };
  }, []);

  return (
    <>
      {isLoading && (
        <div
          style={{
            height: "100vh",  
            display: "flex",
            justifyContent: "center",  
            alignItems: "center", 
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 98,
            background: "#0000003b",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default Loader;
