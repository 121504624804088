'use client';

import PropTypes from 'prop-types';
import { useEffect, useCallback, useMemo, useState } from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';
import { axiosInstance } from 'src/utils/custom-axios';

//
import { usePasswordless } from 'amazon-cognito-passwordless-auth/react';
import _ from 'lodash';
import { AuthContext } from './auth-context';

export const ErrorType = {
  Code: 0,
  CodeExpired: 1,
  User: 2,
  UserIsInactive: 3,
};

class ApiError extends Error {
  name = 'ApiError';

  setDerivateFrom(error) {
    this.error = error;
    return this;
  }

  setErrorType(type) {
    this.errorType = type;
    return this;
  }
}

// ----------------------------------------------------------------------

const STORAGE_KEY = 'idToken';

export function AuthProvider({ children }) {
  function getInitialState() {
    const selectedUser =
      typeof window !== 'undefined' ? window.localStorage.getItem('selectedUser') : '""';
    return selectedUser ? JSON.parse(selectedUser) : {};
  }

  const [userOptions, setUserOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(getInitialState);
  const { signInStatus, tokens, signOut } = usePasswordless();

  const cleanup = () => {
    setUserOptions([]);
    localStorage.clear();
  };

  const initialize = useCallback(async (signInStatus, tokens) => {
    const accessToken = _.get(tokens, 'accessToken');
    const idToken = _.get(tokens, 'idToken');
    const refreshToken = _.get(tokens, 'refreshToken');
    localStorage.setItem('idToken', idToken);
    localStorage.setItem('authorization', `bearer ${accessToken}`);
    localStorage.setItem('refreshToken', refreshToken);
    selectedUser?.lab_cid ? localStorage.setItem('lab_cid', selectedUser?.lab_cid) : null;
    selectedUser?.role ? localStorage.setItem('role', selectedUser?.role) : null;

    axiosInstance
      .get(`/users/me`)
      .then((res) => {
        setUserOptions(res.data.data);
      })
      .catch((err) => {
        logout();
        return Promise.reject(err.message);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (signInStatus !== 'CHECKING' && tokens !== undefined) {
      initialize(signInStatus, tokens);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signInStatus]);

  useEffect(() => {
    localStorage.setItem('selectedUser', JSON.stringify(selectedUser));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  // LOGIN
  const login = useCallback(async (email, username, challenge_code, session_code) => {
    let authResp;
    try {
      authResp = await axiosInstance.post('/auth/challenge', {
        email,
        username,
        challenge_code,
        session_code,
      });
    } catch (err) {
      if (err.response.status === 400)
        throw new ApiError().setDerivateFrom(err).setErrorType(ErrorType.CodeExpired);
      throw new ApiError().setDerivateFrom(err).setErrorType(ErrorType.Code);
    }

    if (!authResp.data.data.AuthenticationResult) throw new ApiError().setErrorType(ErrorType.Code);

    const results = authResp.data.data.AuthenticationResult;

    const { idToken } = results;
    const accessToken = results['AccessToken'];
    const refreshToken = results['RefreshToken'];
    localStorage.setItem('idToken', results['idToken']);
    localStorage.setItem('authorization', `bearer ${accessToken}`);
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('expireAt', results['expireAt']);
    localStorage.setItem('idToken', idToken);
    localStorage.setItem('refreshToken', refreshToken);

    return axiosInstance
      .get(`/users/me`)
      .then((resp) => {
        const userOptions = resp.data.data;
        setUserOptions(userOptions);

        return userOptions;
      })
      .catch((err) => {
        cleanup();
        throw new ApiError().setDerivateFrom(err).setErrorType(ErrorType.User);
      });
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const data = {
      email,
      password,
      firstName,
      lastName,
    };

    const response = await axios.post(endpoints.auth.register, data);

    const { accessToken, user } = response.data;

    localStorage.setItem(STORAGE_KEY, accessToken);
    setUserOptions(user);
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    cleanup();
    signOut();
  }, [signOut]);

  // ----------------------------------------------------------------------
  const memoizedValue = useMemo(
    () => ({
      method: 'jwt',
      //
      loading: signInStatus === 'CHECKING',
      authenticated: signInStatus === 'SIGNED_IN',
      unauthenticated: signInStatus === 'NOT_SIGNED_IN',
      login,
      register,
      logout,
      userOptions,
      setUserOptions,
      selectedUser,
      setSelectedUser,
    }),
    [
      login,
      logout,
      register,
      signInStatus,
      setSelectedUser,
      setUserOptions,
      userOptions,
      selectedUser,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
